import {
  RegionCode,
  RemittanceVouchersBundle,
  RemittanceWithTotalQuantities,
} from '@kaa/api/providers';
import {
  toCurrencyFormat,
  useAsyncCallback,
  useLuxon,
} from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwBadge,
  SwColumn,
  SwDataTable,
  SwDescriptionData,
  SwDescriptionDataItem,
  SwFetchErrorMessage,
  SwGrid,
  SwLink,
  SwLoader,
  SwTitle,
  toggleModalById,
} from '@kaa/ui-flanders/components';
import React, { CSSProperties, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash.get';
import { TableHead } from '../../../../../components';
import { Modals } from '../../../../../constants';
import { useApi } from '../../../../../utils';
import { getConfig } from '../../../../../../common/config';

type RemittancesSearchHistoryDetailModalProps = {
  remittance: RemittanceWithTotalQuantities;
  openRemittanceModal: (
    remittance: RemittanceWithTotalQuantities,
    modalId: Modals,
  ) => void;
};

export const RemittancesSearchHistoryDetailModal = ({
  remittance,
  openRemittanceModal,
}: RemittancesSearchHistoryDetailModalProps) => {
  const { t } = useTranslation();
  const { DateTime } = useLuxon();
  const { providers } = useApi();

  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;

  const { providerId, id: remittanceId } = remittance;

  const [
    { value: remittanceDetails, loading, error },
    getRemittanceDetails,
  ] = useAsyncCallback(
    async () =>
      (await providers.getRemittance(providerId, remittanceId)).data.data,
    [providers],
    { loading: true },
  );

  useEffect(() => {
    getRemittanceDetails();
  }, [getRemittanceDetails]);

  if (loading && !remittanceDetails) {
    return <SwLoader />;
  }

  if (error || !remittanceDetails) {
    return <SwFetchErrorMessage onClick={getRemittanceDetails} />;
  }

  const {
    voucherBundle,
    communication,
    serviceDate,
    supportType,
    creationDate,
    firstRefundDate,
    refundedAmount,
  } = remittanceDetails;

  const SwDescriptionDataItemStyle = {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    margin: 0,
  };
  const SwDescriptionDataItemColumn = { width: '3', widthS: '6' };

  const SwColumnVoucherStyle = { width: '16%', padding: 0 };

  const getVouchersTotal = (key: keyof RemittanceVouchersBundle) =>
    voucherBundle
      .map((voucher) => voucher[key])
      .reduce((a, b) => Number(a) + Number(b), 0);

  const head = [
    {
      text: '',
      style: { width: '25%' } as CSSProperties,
      colSpan: regionCode === RegionCode.BE_WAL ? 4 : 2,
      rowSpan: 2,
      scope: 'rowgroup',
    },
    {
      text: t(i18nKeys.remittances.general.vouchers),
      style: { border: '0' } as CSSProperties,
      className: 'vl-u-align-center',
      children: (
        <SwGrid style={{ margin: '1rem 0 0', minWidth: '52rem' }}>
          <div style={SwColumnVoucherStyle}>
            {t(i18nKeys.remittances.general.announced)}
          </div>
          <div style={SwColumnVoucherStyle}>
            {t(i18nKeys.remittances.general.read)}
          </div>
          <div style={SwColumnVoucherStyle}>
            {t(i18nKeys.remittances.general.processing)}
          </div>
          <div style={SwColumnVoucherStyle}>
            {t(i18nKeys.remittances.general.toCorrect)}
          </div>
          <div style={SwColumnVoucherStyle}>
            {t(i18nKeys.remittances.general.refused)}
          </div>
          <div style={SwColumnVoucherStyle}>
            {t(i18nKeys.remittances.general.reimbursed)}
          </div>
        </SwGrid>
      ),
    },
  ];

  const BodyHead = ({ title }: { title: string }) => (
    <th
      style={{ width: '10%', whiteSpace: 'inherit' }}
      className="vl-u-align-center"
    >
      {title}
    </th>
  );

  return (
    <>
      <SwTitle tagName="h2" className="vl-u-spacer--medium">
        {t(i18nKeys.remittances.detailModal.title)} {remittanceId}
      </SwTitle>
      <SwTitle tagName="h3" tagStyle="h4">
        {t(i18nKeys.remittances.detailModal.generalView.title)}
      </SwTitle>

      <SwColumn width="8" widthM="12" className="vl-u-align-left">
        <SwDescriptionData className="vl-u-spacer--small">
          <SwDescriptionDataItem
            label={remittanceId}
            column={SwDescriptionDataItemColumn}
            style={SwDescriptionDataItemStyle}
          >
            {t(i18nKeys.remittances.general.number)}
          </SwDescriptionDataItem>
          <SwDescriptionDataItem
            label={communication || '-'}
            column={SwDescriptionDataItemColumn}
            style={SwDescriptionDataItemStyle}
          >
            {t(i18nKeys.remittances.general.reference)}
          </SwDescriptionDataItem>
          <SwDescriptionDataItem
            label={DateTime.fromISO(serviceDate).toFormat('LL/yyyy')}
            column={SwDescriptionDataItemColumn}
            style={SwDescriptionDataItemStyle}
          >
            {t(i18nKeys.remittances.general.monthPrestation)}
          </SwDescriptionDataItem>
          <SwDescriptionDataItem
            label={t(i18nKeys.general.supportType[supportType])}
            column={SwDescriptionDataItemColumn}
            style={SwDescriptionDataItemStyle}
          >
            {t(i18nKeys.remittances.general.type)}
          </SwDescriptionDataItem>
          <SwDescriptionDataItem
            label={providerId}
            column={SwDescriptionDataItemColumn}
            style={SwDescriptionDataItemStyle}
          >
            {t(i18nKeys.remittances.general.reimbursementCenter)}
          </SwDescriptionDataItem>
          <SwDescriptionDataItem
            label={DateTime.fromISO(creationDate).toFormat('dd/LL/yyyy')}
            column={SwDescriptionDataItemColumn}
            style={SwDescriptionDataItemStyle}
          >
            {t(i18nKeys.remittances.general.arrivalDate)}
          </SwDescriptionDataItem>
          <SwDescriptionDataItem
            label={
              firstRefundDate
                ? DateTime.fromISO(firstRefundDate).toFormat('dd/LL/yyyy')
                : '-'
            }
            column={SwDescriptionDataItemColumn}
            style={SwDescriptionDataItemStyle}
          >
            {t(i18nKeys.remittances.general.firstReimbursement)}
          </SwDescriptionDataItem>
          <SwDescriptionDataItem
            label={refundedAmount ? toCurrencyFormat(refundedAmount) : '-'}
            column={SwDescriptionDataItemColumn}
            style={SwDescriptionDataItemStyle}
          >
            {t(i18nKeys.remittances.general.totalReimbursement)}
          </SwDescriptionDataItem>
        </SwDescriptionData>
      </SwColumn>

      <hr
        className="vl-u-spacer--medium"
        style={{ height: '.1rem', border: 'none', backgroundColor: '#cbd2da' }}
      />

      <SwTitle tagName="h3" tagStyle="h4">
        {t(i18nKeys.remittances.detailModal.monthlyView.title)}
      </SwTitle>

      <div className="vl-u-table-overflow vl-u-spacer--medium">
        <SwDataTable modLine>
          <TableHead elements={head}>
            <tr>
              <td className="vl-u-align-center">
                <SwGrid style={{ margin: 0 }}>
                  <div style={SwColumnVoucherStyle}>
                    {getVouchersTotal('announcedVouchers')}
                  </div>
                  <div style={SwColumnVoucherStyle}>
                    {getVouchersTotal('readVouchers')}
                  </div>
                  <div style={SwColumnVoucherStyle}>
                    {getVouchersTotal('processingVouchers')}
                  </div>
                  <div style={SwColumnVoucherStyle}>
                    {getVouchersTotal('incorrectVouchers')}
                  </div>
                  <div style={SwColumnVoucherStyle}>
                    {getVouchersTotal('refusedVouchers')}
                  </div>
                  <div style={SwColumnVoucherStyle}>
                    {getVouchersTotal('validVouchers')}
                  </div>
                </SwGrid>
              </td>
            </tr>
          </TableHead>
          <tbody>
            <tr>
              <BodyHead
                title={t(
                  i18nKeys.remittances.detailModal.monthlyView.monthOfEmission,
                )}
              />
              {regionCode === RegionCode.BE_WAL && (
                <>
                  <BodyHead
                    title={t(
                      i18nKeys.remittances.detailModal.monthlyView
                        .MONTH_OF_WORK,
                    )}
                  />
                  <BodyHead
                    title={t(
                      i18nKeys.remittances.detailModal.monthlyView
                        .subsidyCalculationBasis,
                    )}
                  />
                </>
              )}

              <BodyHead
                title={t(
                  i18nKeys.remittances.detailModal.monthlyView
                    .reimbursementValue,
                ).toLowerCase()}
              />

              <th className="vl-u-align-center" />
            </tr>
            {voucherBundle.map(
              (
                {
                  voucherTotalValue,
                  voucherWorkDate,
                  reimbursementRefDateType,
                  announcedVouchers,
                  readVouchers,
                  processingVouchers,
                  incorrectVouchers,
                  refusedVouchers,
                  validVouchers,
                  emissionDate,
                },
                i,
              ) => (
                <tr key={emissionDate + i}>
                  <td className="vl-u-align-center">
                    {DateTime.fromISO(emissionDate).toFormat('LL/yyyy')}
                  </td>
                  {regionCode === RegionCode.BE_WAL && (
                    <>
                      <td className="vl-u-align-center">
                        {voucherWorkDate
                          ? DateTime.fromISO(voucherWorkDate).toFormat(
                              'LL/yyyy',
                            )
                          : '-'}
                      </td>
                      <td className="vl-u-align-center">
                        {t(
                          i18nKeys.remittances.detailModal.monthlyView[
                            reimbursementRefDateType
                          ],
                        ).toLowerCase()}
                      </td>
                    </>
                  )}
                  <td className="vl-u-align-center">
                    {voucherTotalValue
                      ? toCurrencyFormat(voucherTotalValue)
                      : '-'}
                  </td>
                  <td className="vl-u-align-center">
                    <SwGrid style={{ margin: 0 }}>
                      <div style={SwColumnVoucherStyle}>
                        {announcedVouchers}
                      </div>
                      <div style={SwColumnVoucherStyle}>{readVouchers}</div>
                      <div style={SwColumnVoucherStyle}>
                        {processingVouchers}
                      </div>
                      <div style={SwColumnVoucherStyle}>
                        {incorrectVouchers > 0 ? (
                          <SwLink
                            tagName="button"
                            onClick={() => {
                              toggleModalById(
                                Modals.REMITTANCES_SEARCH_HISTORY_DETAIL_MODAL,
                              );
                              openRemittanceModal(
                                remittance,
                                Modals.REMITTANCES_SEARCH_HISTORY_INCORRECT_MODAL,
                              );
                            }}
                          >
                            <SwBadge
                              style={{
                                marginRight: '.5rem',
                                minWidth: '2.75rem',
                                width: '2.75rem',
                                height: '2.75rem',
                              }}
                              modMedium
                              modWarning
                              icon={Icon.ALERT_CIRCLE}
                            />
                            {incorrectVouchers}
                          </SwLink>
                        ) : (
                          <>{incorrectVouchers}</>
                        )}
                      </div>
                      <div style={SwColumnVoucherStyle}>
                        {refusedVouchers > 0 ? (
                          <SwLink
                            tagName="button"
                            onClick={() => {
                              toggleModalById(
                                Modals.REMITTANCES_SEARCH_HISTORY_DETAIL_MODAL,
                              );
                              openRemittanceModal(
                                remittance,
                                Modals.REMITTANCES_SEARCH_HISTORY_REFUSED_MODAL,
                              );
                            }}
                          >
                            <SwBadge
                              style={{
                                marginRight: '.5rem',
                                transform: 'rotate(45deg)',
                                minWidth: '2.75rem',
                                width: '2.75rem',
                                height: '2.75rem',
                              }}
                              modMedium
                              modError
                              icon={Icon.PLUS_CIRCLE}
                            />
                            {refusedVouchers}
                          </SwLink>
                        ) : (
                          <>{refusedVouchers}</>
                        )}
                      </div>
                      <div style={SwColumnVoucherStyle}>{validVouchers}</div>
                    </SwGrid>
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </SwDataTable>
      </div>
    </>
  );
};
